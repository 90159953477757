.rink {
    .faceoff-guide {
        position: absolute;
        height: 4em;
        width: 4em;
        background: indianred;
        opacity: 0.5;
        border-radius: 50%;
        z-index: 999;

        &:hover {
            background: darkred;
        }

        &.top_left {
            left: 11.7%;
            top: 15%;
        }

        &.top_right {
            right: 11.7%;
            top: 15%;
        }

        &.bottom_right {
            right: 11.7%;
            bottom: 16%;
        }

        &.bottom_left {
            left: 11.7%;
            bottom: 16%;
        }

        &.middle_bottom_left {
            left: 36.2%;
            bottom: 16%;
        }

        &.middle_bottom_right {
            right: 36.2%;
            bottom: 16%;
        }

        &.middle_top_right {
            right: 36.2%;
            top: 15%;
        }

        &.middle_top_left {
            left: 36.2%;
            top: 15%;
        }

        &.center {
            left: 46.2%;
            top: 41.3%;
        }

        &.active-guide {
            background: green;
        }
    }
}
