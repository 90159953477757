.game-report {
    padding: 20px;

    .btn {
        margin-bottom: 10px;
        height: 30px;
    }

    h1 {
        text-align: center;
        margin-top: 30px;
        font-size: 1.5em;
    }

    table {
        margin-bottom: 30px;
        border: 1px solid black;
        box-shadow: 0px 0px 7px 0px black;

        th {
            padding: 5px;

            &:first-child {
                text-align: left;
                padding-left: 5px;
            }
        }

        td {
            padding: 5px;

            &.left {
                text-align: left;
            }
        }

        tbody {
            text-align: center;
            tr {
                &:nth-child(odd) {
                    background: #4e4e4e;
                }
            }
        }

        &.goalie {
            width: 100%;
        }
    }
}