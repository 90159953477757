.label {
    &-line {
        display: inline;
        float: left;
        margin-right: 10px;
    }
}

.line-toggles {
    display: inline;
    float: right;

    .line {
        height: 1em !important;
        width: 5em;
        margin-top: 0;
        padding: 5px;
    }
}

.player-numbers {
    clear: both;
}

.player-line {
    &-1 {
        color: #61e961;
    }
    &-2 {
        color: #21b4ff;
    }
    &-3 {
        color: #FFB718;
    }
    &-4 {
        color: #f53d3d;

        &.active {
            color: #fdcece;
        }
    }
}
