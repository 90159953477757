#tagger {
  .modal-overlay {
    &:not(.open) {
      display: none;
    }
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,.60);
    z-index: 9999;
    .modal-content {
      padding: 2.7em 1.3em 1.3em 1.3em;
      background: #333;
      //margin: 0 30px;
      box-shadow: 0 10px 20px rgba(0,0,0,.75);
      position: relative;
      transform: translate(-50%, -50%);
      margin-left: 50%;
      margin-top: calc(50% + 4em);
      max-height: calc(100vh - 60px);
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      // width: 98%;
      .close {
        position: absolute;
        top: 15px;
        right: 15px;
        height: 20px;
        width: 20px;
        text-align: right;
        cursor: pointer;
      }
      .section:first-child {
        border-top: none!important;
        box-shadow: none!important;
      }
      .section:last-child {
        padding-bottom: 0;
      }
    }
    &.pen-modal {
        .modal-content {
          padding-top: 0;
          transform: none;
          margin: 1.5em;
          width: auto;
          .btn-multi-line {
            .btn {
              span {
                font-size: 1.27em;
                line-height: 1em;
                padding-left: .25rem;
                padding-right: .25rem;
              }
            }
            &.player-list {
              font-size: 1.5em;
              .btn {
                height: 2.5em;
              }
            }
          }

          & .section {
            padding-top: 10px;
            padding-bottom: 10px;
          }
          
        }
    }

    &.goal-modal {
      .top-row {
        .btn {
          span {
            font-size: 1.8em;
            line-height: unset;
          }
        }
      }
    }

    &.team {
      h1 {
        font-size: 2em;
        margin-bottom: .5em;
        text-align: center;
      }
    }

    &.other {
        h1 {
            font-size: 1.5em;
            margin-bottom: .5em;
            text-align: center;
        }
    }
  }
  
}
