.alert {
    //height: 50px;
    background: indianred;
    border-radius: 2px;
    text-align: center;
    padding: 17px;
    margin-bottom: 25px;
    border-bottom: 1px solid darkred;
}

.message {
    position: fixed;
    bottom: 0px;
    left: 0px;
    height: 50px;
    width: 100%;
    background: #66a666;
    z-index: 9999;
    padding: 13px;
    text-align: center;
    border-radius: 0px;
    border-top: 2px solid darkgreen;
    font-size: 1.5em;

    &.flag {
        background: #00008B;
        border-top: 2px solid #00008B;
    }
}
