.modal-overlay {
    &.shootout {
        .shootout-scores {
            font-size: 2em;
            text-align: center;

            .score {
                display: inline;
            }
        }

        .net {
            max-width: 350px;
        }
    }
}
