@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}


@mixin gradient($from, $to) {
  background: $from; /* Old browsers */
  background: -moz-linear-gradient(top,  $from 0%, $to 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  $from 0%,$to 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  $from 0%,$to 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=0 ); /* IE6-9 */
}

@mixin clearfix(){
  &:before,
  &:after{
    content: " ";
    display: table;
  }

  &:after{
    display: block;
    clear: both;
    height: 1px;
    margin-top: -1px;
    visibility: hidden;
  }
  &{
    *zoom: 1;
  }
}
