#tagger.toi {
    .forwards, .defense, .goalies {
        width: 100%;
        .btn {
            height: 3.5em;
        }
    }
    .action-row {
        .btn {
            height: 6em;
        }
    }
}
