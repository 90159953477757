.clear {
  clear:both;
}

.center {
  text-align: center;
}

@keyframes direction {
  0% { opacity: .3; }
  50% { opacity: 1; }
  100% { opacity: .3; }
}

//$fifty: 50%;

#tagger {
  font-family: 'exo', Impact, sans-serif;
  background: #333;
  color: #fff;
  // min-height: 100vh;
  text-transform: uppercase;
  font-size: calc(1.85vw);
  overflow: auto;
  .col-6 {
    width: 50%;
    float:left;
    box-sizing: border-box;
    .col-6 {
      padding-right:.2707035em;
      padding-left: .2707035em;
    }
  }
  .col-4 {
    width: 33.33333333%;
    float:left;
    box-sizing: border-box;
    .col-4 {
      padding-right:.2707035em;
      padding-left: .2707035em;
    }
  }
  .col-8 {
    width: 66.6666666%;
    float:left;
    box-sizing: border-box;
    .col-8 {
      padding-right:.2707035em;
      padding-left: .2707035em;
    }
  }
  .pl-1 {
    padding-left: .25rem!important;
  }
  .pr-1 {
    padding-right: .25rem!important;
  }
  // .inactive {
  //   opacity: .3;

  //   &#ice {
  //       opacity: .5;
  //   }
  // }
  .sep-left {
    border-left: 1px solid #555;
    padding-left: .3519145em;
  }
  .sep-right {
    border-right: 1px solid #191919;
    //padding-right: .3519145em;
  }

  .section {
    border-top: 1px solid #191919;
    box-shadow: inset 0 1px 0 #555;
    padding-top: 15px;
    padding-bottom: 15px;
    position:relative;
    z-index: 1;
    @include clearfix;
    &.no-border {
      border-top: none;
      box-shadow: none;
    }
  }
  .flex {
    display: flex;
  }
  .flex-1 {
    flex: 1;
  }
  .flex-quarter {
      flex: 0.25;
  }
  .mw-25 {
    max-width: 25%;
  }
  .label {
    font-style: italic;
    padding-bottom: 5px;
  }
  #interface {
    padding: .5em;
  }
  .players {
    .inner {
      margin-left: -.3519145em;
      margin-right: -.3519145em;
    }
    .label {
      padding-left: .3519145em;
    }
    .btn {
      // font-size: 1.3em;
      // height: 3.4em;
      font-size: 1.6em;
      height: 2.6em;
    }
    .forwards, .defense, .goalies {
      padding: 0 .3519145em;
    }
    .forwards {
      width: 55%;
      float:left;
      box-sizing: border-box;
    }
    .defense {
      width: 34%;
      float:left;
      box-sizing: border-box;
    }
    .goalies {
      width: 11%;
      float:left;
      box-sizing: border-box;
      .unknown {
        padding-left: 1em;
        padding-right: 1em;
        font-size: 1em;
        line-height: 3.9em;
      }
    }
  }
  #ice {
      .btn {
          span {
              font-size: 1.6em;
          }
      }
    .direction-indicator {
      // line-height: 2.5em;
      // font-size: .9em;
      // width:12em;
      // @include clearfix;
      // margin: 0 auto;
      // float:none;
      // padding-left: 0;
      // padding-right: 0;
      // span {
      //   display: block;
      // }
      // img {
      //   display: block;
      //   width: 3em;
      // }

      &.highlighted {

          
          //font-size: 1.1em;
          margin-bottom: -.3em
          &:after {
            content: "";
            box-shadow: 0px 0px 15px 0px rgb(249, 38, 38), 0 0 7px rgba(255,255,255,1),0px 0px 15px 0px rgb(186, 56, 56), 0 0 3px rgba(255,255,255,1), 0 0 2px rgba(255,255,255,1), inset 0px 0px 15px 0px rgba(249, 38, 38, .5);
            width: 100%;
            height: 2.8em;
            display: block;
            position: absolute;
            left: 0;
            top:0;
            border-radius: .25em;
            opacity: 0;
            animation: direction 2.5s infinite;
            
          }
      }
    }
    .defending, .attacking {
      padding-top: .85em;
    }
    .ltr {
      text-align: center;
      .defending {
        float:left;
        margin-left: 3em;
      }
      .attacking {
        float:right;
        margin-right: 3em;
      }
      .direction-indicator {
        display: inline-block;
        height: auto;
        padding-left: 1em;
        padding-right: 1em;
        div {
          float: left;
          //.3519145em;
      //    margin:  .1407658em .3519145em 0 .85em;
        }
        img {
          float:right;
          margin:.85em  0 0 0;
        }
      }
      .skip-faceoff-margin {
        margin-right: 2.5em;
        padding-left: 2.5em;
      }
    }
    .rtl {
      .defending {
        float:right;
        margin-right: 3em;
      }
      .attacking {
        float:left;
        margin-left: 3em;
      }
      .direction-indicator {
        span {
          float: right;
          //margin:  .1407658em .85em 0 .3519145em;
        }
        div {
          float: left;
        }
        img {
          float:left;
          margin:.85em 0 0 0;
          transform:rotate(180deg);
        }
      }
    }
    img.direction {
      &.left {
        transform: rotate(180deg);
        // float: left;
      }
    }
    div.direction {
      &.left {
        // transform: rotate(180deg);
        float: right;
        padding: 0;
        margin: 0;
      }
    }
    .rink {
      margin-top: 1em;
      img {
        width: 100%;
      }
    }
  }
  .action-row {
    padding-bottom: 0;
    > div {
      display:flex;
    }
    .btn {
      font-size: 1.1em;
      height: 7.4em;
      display: flex;
      flex-grow: 1;
      flex-basis: 0;
      span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 90%;
      }
    }

    &.tagger-bottom-buttons {
        .btn {
            span {
                line-height: 1em;
                font-size: 1.6em;
            }
        }
    }
  }
}

.card {
    @extend .btn;
    height: auto;
    margin:.2707035em;
    padding: 1em;
    text-align:left;
}


.toi-actions {
  .btn {
    span {
      font-size: 2em;
      line-height: 1em;
    }
  }
}

.video-actions {
  .btn {
    span {
      font-size: 2em;
    }
  }
}

.penalty-toggles {
  .btn {
    span {
      font-size: 1.27em;
    }
  }
}
