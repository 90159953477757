.header {
//    @include clearfix;
  background: #000;
  position: relative;
  z-index: 0;
  .host, .time, .opponent {
    float:left;
    width: 33.33333%;
  }
  .color-bar {
    background: #333;
    height:300px;
    position: absolute;
    z-index: 0;
    margin-bottom: -300px;
    left: 0;
    right: 0;
    top: 2em;
  }

  .menu {
      &.padded {
          padding-top: 5px;
          padding-left: 5px;
      }
  }

  .team-name {
    @include clearfix;
    .menu, .undo {
      // font-size: .8em;
      font-size: 1.2em;
      float:left;
      // padding: .5em;
      padding: .25em;
    }
    .menu {
      float:left;
    }
    .undo {
      float:right;

    }
    .name {
      padding: .4em 0em 0em 0em;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 70%;
    }
  }
  .team-stats {
    @include clearfix;
    background: #333;
    border-top: 1px solid #555;
    display: flex;
    .label {
      font-size: .65em;
      font-style: italic;
      margin-bottom: .5em;
    }
    .shots, .penalties, .score {
      box-sizing: border-box;
    }
    .shots {
      text-align: center;
      width: 30%;
      float:left;
      border-right: 1px solid #191919;
      .shotnum {
        font-size: 1.3em;
      }
    }
    .penalties {
      width: 40%;
      float:left;
      text-align:center;
      padding: 0 .5em;
      .num, .pen-time {
        font-size: .8em;
        float:left;
        text-align: center;
      }
      .num {
        width: 45%;
        float:left;
        clear: left;
      }
      .pen-time {
        width: 55%;
        float:left;
        clear:right;
      }
      .pen-close {
        color: red;
        text-transform: lowercase;
        font-size: 15px;
        margin-left: 10px;
      }
    }
    .score {
      width: 30%;
      float:left;
      text-align: center;
      font-size: 2.5em;
    }
  }
  .host {
    .penalties {
      border-right: 1px solid #191919;
      border-left: 1px solid #555;
    }
    .score {
      border-left: 1px solid #555;
    }
    .team-stats {
      border-radius: 0 .25em 0 0;
      padding: 7px 0 7px 7px;
    }
    .team-name {
      .name {
        float:right;
        text-align: right;
      }
    }
  }
  .opponent {
    .penalties {
      border-right: 1px solid #191919;
      border-left: 1px solid #555;
    }
    .score {
      border-right: 1px solid #191919;
    }
    .shots {
      border-left: 1px solid #555;
    }
    .team-stats {
      border-radius: .25em 0 0 0;
      padding: 7px 0 7px 7px;
    }
    .name {
      float:left;
    }
  }
  .host, .opponent {
    position: relative;
    z-index: 1;
  }
}
.time {
  font-style: italic;
  .inner-container {
    border-bottom: 1px solid #555;
    border-radius: 0 0 .25em .25em;
    padding: .5em .5em .25em .5em;
    box-sizing: border-box;
    background: #000;
    @include clearfix;
    // margin-bottom: -10px;
    position:relative;
    z-index: 100;
  }
  .current-time {
    width: 100%;
    float:left;
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 0;
    line-height: .6em;
    padding-top: .2em;
    span {
      font-size:.5em;
    }
  }
  .adj-time {
    border: .2em solid #fff;
    text-transform: none;
    border-radius: 500px;
    // text-align: center;
    //width: 20%;
    //padding: 6.1% 4.4%;
    // display: block;
    // box-sizing: border-box;
    position: absolute;
    width:3.4em;
    height: 3.4em;
    div {
      transform: translate(-50%,-50%);
      margin-left: 50%;
      margin-top: 50%;
    }
    &.minus {
      left: 0;
      margin-left: .7em;
    }
    &.plus {
      right: 0;
      margin-right: .7em;
    }
  }
}
