/* Import all SCSS here */
@import 'reset';
@import 'mixins';
@import 'fonts';
@import 'buttons';
@import 'form';
@import 'login';
@import 'scoreboard';
@import 'select-game';
@import 'configure-game';
@import 'tagger';
@import 'modal';
@import 'alert';
@import 'faceoff-guides';
@import 'menu';
@import 'danger-zones';
@import 'net';
@import 'shootout';
@import 'video-tagger';
@import 'shot';
@import 'toi-tagger';
@import 'game-report';
@import 'transforms';
@import 'penalty-type-modal';
@import 'line-toggles';
@import 'faceoff-alert';
@import 'modal-time-buttons';
@import 'flex';
@import 'event-editor';

body * {
    &:not(input) {
        -webkit-user-select: none !important;
    }
}
