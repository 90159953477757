.events-container {
    height: 85vh;
    overflow: scroll;
    overflow-x: hidden;
    padding-right: 15px;
}

.event-card {
    margin-top: 10px;
    padding: 20px;
    // background-color: darkslategrey;
    background: linear-gradient(to bottom, #555555 0%, #3a3a3a 100%);
    border-radius: 25px;

    .period-display {
        font-size: 30px;
        display: inline-block;
        width: 33%;
    }

    .event-type-display {
        font-size: 30px;
        display: inline-block;
        width: 33%;
        text-align: center;
        color: #ff7373;
    }

    .time-display {
        font-size: 30px;
        display: inline-block;
        width: 33%;
        text-align: right;
    }

    .event-col {
        width: 31%;
        display: inline-block;
        margin-right: 2%;
        margin-top: 2%;
        font-size: 25px;

        select {
            font-size: 17px;
        }
    }

    .delete-btn {
        display: inline-block;
        padding: 10px;
        margin-top: 10px;
        float: none;
        margin-bottom: 0px;
    }

    // .event-input {
    //     width: 33%;
    //     font-size: 20px;
    // };

    .black-select {
        color: black !important;
    }
}