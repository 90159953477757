.modal-time-buttons {
    .label {
        padding-bottom: 0;
        text-align: center;
    }

    .btn {
        height: 3em;
    }
}
