.sidebar {
    &.closed {
        display: none;
    }

    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background: #262626;
    z-index: 999;

    .exit {
        right: 0;
        position: absolute;
        margin-right: 10px;
        margin-top: 10px;
        font-size: 1.5em;
        cursor: pointer;
    }

    .items {
        margin-top: 50px;
        padding-left: 20px;
        padding-right: 20px;

        .item {
            padding: 25px;
            border-bottom: 2px solid grey;

            &.menu-upload {
                position: relative;

                &.flashing {
                    &:after {
                        content: "";
                        box-shadow: 0px 0px 15px 0px #4997c5, 0 0 7px white, 0px 0px 15px 0px #118cc8, 0 0 3px white, 0 0 2px white, inset 0px 0px 15px 0px #21b4ff;
                        width: 10em;
                        display: block;
                        position: absolute;
                        height: 2.5em;
                        left: 0;
                        top:15px;
                        border-radius: .25em;
                        opacity: 0;
                        animation: direction 1s infinite;
                    }
                }
            }
        }
    }
}
