.configure-game {
    .roster-container {
        padding: 1.5em;
        position: absolute;
        bottom: 0;
        padding-bottom: 100px;
        right: 0;
        left: 0;
        top: 6em;
        z-index: 1;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    table.roster {
        width: 100%;
        border-collapse:separate;
        td, th {
            padding: .5em .5em;
            text-align: center;
            white-space: nowrap;
            font-size: .9em;
            &.name, &.note {
                text-align:left;
            }
        }
        th {
            font-size: .7em;
            border-bottom: 1px solid #191919;
        }
        tr {
            &:last-child {
                td {
                    border-bottom: none;
                }
            }

        }
        td {
            vertical-align: middle;
            border-top: 1px solid #555;
            border-bottom:1px solid #191919;
            white-space: nowrap;
            &.starting, &.active {
                width: 6.5em;
                .btn {
                    //width: auto;
                }
            }
            &.name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 15em;
            }
            .btn, .btn-group {

            }
            .btn {
                font-size: .8em;
                span {
                    vertical-align: bottom;
                    padding-bottom: .7em;
                    font-size:.9em;
                    padding-left: .65em;
                    padding-right: .65em;
                }
                &:before {
                    content:'';
                    display: block;
                    position: absolute;
                    height: .35em;
                    background:#fff;
                    top: .5em;
                    border-radius: 5em;
                    width: 2em;
                    left: 50%;
                    margin-left: -1em;
                    background: #333;
                    box-shadow: 0 1px 0 rgba(255,255,255,.3), 0 -1px 0 rgba(0,0,0,.3);
                }
                &.highlighted {
                    position:relative;
                    &:before {
                        content:'';
                        display: block;
                        position: absolute;
                        height: .35em;
                        background:#fff;
                        top: .5em;
                        border-radius: 5em;
                        width: 2em;
                        left: 50%;
                        margin-left: -1em;
                        box-shadow:
                            0 1px 0 rgba(255,255,255,.3),
                            0 -1px 0 rgba(0,0,0,.3),
                            0 0 1em rgba(0,255,0,1),
                            inset 0 0 .5rem rgba(0,255,0,.2),
                            inset 0 0 .1rem rgba(0,255,0,1),
                            inset 0 0 .1rem rgba(0,255,0,1),
                            inset 0 0 .15rem rgba(0,255,0,1),
                            inset 0 0 .15rem rgba(0,255,0,1);
                    }
                    &.negative {
                        &:before {
                            box-shadow:
                                0 1px 0 rgba(255,255,255,.3),
                                0 -1px 0 rgba(0,0,0,.3),
                                0 0 1em rgba(255,0,0,1),
                                inset 0 0 .5rem rgba(255,0,0,.2),
                                inset 0 0 .5rem rgba(255,0,0,.2),
                                inset 0 0 .5rem rgba(255,0,0,.2),
                                inset 0 0 .5rem rgba(255,0,0,.2),
                                inset 0 0 .15rem rgba(255,0,0,1);
                        }
                    }
                }
            }

            &.note {
                vertical-align: bottom;
                input[type="text"] {
                    background:none;
                    border:none;
                    //border-bottom: 1px solid #555;
                    border-bottom: none;
                    margin-bottom: .25em;
                    width: 100%;
                    border-radius: 0!important;
                    -webkit-appearance: none!important;
                    -webkit-border-radius: 0;
                    box-shadow: none;
                    padding-bottom: .5em;
                }
            }
        }
    }
    .header {
        border-bottom: 1px solid #555;
        .time {
            //font-size: .8em;
            .current-time {
                span {
                    font-size: .4em;
                }
            }
            .inner-container {
                border-radius: 0;
                border-bottom: none;
            }
        }
        .teams {
            .away {
                .team-name {
                    text-align: left;
                }
            }
            .home {
                .team-name {
                    text-align: right;
                }
            }
        }
        .date-loc, .back {

            //padding-bottom: 1em;
            font-size: .8em;
        }
        .opponent {
            text-align:right;
        }
        .back, .back a {
            color: #fff;
            text-decoration: none;
        }
        .team-name {
            padding: .5em;
            .name {
                width: 100%;
                font-size: 1.2em;
            }
            .location {
                font-size: .8em;
            }
        }
        .team-stats {
            height: 3em;
        }
        .color-bar {
            top:4.5em;
            display:none;
        }
    }

    .alternate-jersey-btn {
        width: 30%;
        margin-left: 35%;
    }
}
