.danger-zone {
    position: absolute;
    opacity: 0;

    &.high_danger {
        background: red;
        top: 40.5%;
        right: 5.5%;
        width: 11%;
        height: 18%;

        &.left {
            left: 5.5%;
        }
    }

    &.mid_danger_top {
        background: green;
        clip-path: polygon(0 0, 0% 100%, 100% 100%);
        top: 22.6%;
        width: 11.2%;
        right: 5.4%;
        height: 18%;

        &.left {
            left: 5.4%;
            clip-path: polygon(100% 0, 0% 100%, 100% 100%);
        }
    }

    &.mid_danger_bottom {
        background: green;
        clip-path: polygon(0 0, 100% 0, 0 100%);
        top: 58.4%;
        width: 11.3%;
        right: 5.4%;
        height: 18.4%;

        &.left {
            left: 5.4%;
            clip-path: polygon(0 0, 100% 0, 100% 100%);
        }
    }

    &.mid_danger {
        background: green;
        top: 23.5%;
        height: 52.3%;
        right: 16.5%;
        width: 7.4%;

        &.left {
            left: 16.5%;
        }
    }
}
