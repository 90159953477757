.net-container {
    text-align: center;

    .inner {
        display: inline-block;
        position: relative;

        .net {
            width: 350px;
            display: block;
        }

        .puck {
            width: 1em;
            height: 1em;
            position: absolute;
            background: black;
            z-index: 999;
            border-radius: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
