#faceoff-alert {
    position: absolute;
    width: 98%;
    // background: #118cc8;
    background: red;
    padding: 2em;
    text-align: center;
    z-index: 999;
    border-radius: .25em;
    font-size: 2.25em;
    height: 28%;

    &:after {
      box-shadow: 0px 0px 15px 0px #4997c5, 0 0 7px white, 0px 0px 15px 0px #118cc8, 0 0 3px white, 0 0 2px white, inset 0px 0px 15px 0px #21b4ff;
      animation: direction 1s infinite;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      content: "";
      border-radius: .25em;
    }

    #faceoff-alert-text {
      margin-top: 10%;
    }
}
