#tagger.video-tagger {
    .configure-game {
        .header {
            .teams {
                display: flex;
                .host {
                    width: unset;
                }
                .opponent {
                    margin-left: auto;
                }
            }
        }
    }
    .period {
        text-align: center;
        font-size: 1.5em;
    }
}
@media screen and (max-width:780px) {
    #tagger.video-tagger {
        #interface {
            font-size: 3em;
            .btn-row-3 .btn {
                //width: calc(100% - .2707035em * 2);
            }
        }
    }
    .section.end {
        font-size:1rem;
    }
}