@font-face {
    font-family: 'exo';
    src: url('fonts/exo2-bold-webfont.woff2') format('woff2'),
         url('fonts/exo2-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'exo_italic';
    src: url('fonts/exo2-bolditalic-webfont.woff2') format('woff2'),
         url('fonts/exo2-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
