.btn {
  @include border-radius(.25em);
  @include gradient(#666666,#555555);
  box-shadow: 0 .1rem .2rem rgba(0,0,0,.3), inset 0 .05rem 0 rgba(255,255,255,.3), inset 0 -.05rem 0 rgba(0,0,0,.3);
  text-align: center;
  float:left;
  box-sizing: border-box;
  font-size: .8em;
  margin: .2707035em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  line-height: 1em;
  white-space: normal;
  overflow: visible;
  text-overflow: inherit;
  // hyphens: auto;
  display: table;
  height: 4.5em;
  position: relative;
  span {
    display: table-cell;
    vertical-align: middle;
    small {
      font-size: .7em;
    }
  }

  &.blue {
    @include gradient(#1B5E86,#237AA2);
  }

  &.green {
    @include gradient(#2a7214,#30a712);
  }

  &:active, &.active {
    @include gradient(#5D2B2B,#933D3D);
    box-shadow: inset 0 .1rem .2rem rgba(0,0,0,.3), inset 0 .05rem 0 rgba(255,255,255,.1), inset 0 .05rem 0 rgba(0,0,0,.3);

    &.secondary {
        @include gradient(#1B5E86,#237AA2);
    }

    &.extra-player {
        @include gradient(#1f731f,#2b852b);
    }
  }


  &.save {
    @include gradient(#2993C3,#226D9B);
    border: none;
    display: inline-block;
    float: none;
    font-size: 1.1em;
    line-height: 3em;
    padding-left: 3em;
    padding-right: 3em;
    height:auto;
    &:active, &.active {
      @include gradient(#1B5E86,#237AA2);
      border-bottom: 1px solid #237AA2;
      border-top: 1px solid #1B5E86;
    }

    &.small {
      font-size: 0.75em;
    }

    &.wide {
      width: 97%;
    }

    &.half {
        width: 48%;
    }

    &.right {
        float: right;
    }

    &.red {
        @include gradient(#5D2B2B,#933D3D);
    }

  }
  &.btn-small {
      height: 3em;

      width: 100%;
  }

  &.faceoff {
    font-size: 1.3em;
    height: 2.5em;
  }

  &.shootout-player {
    font-size: 1em;
  }

  &.select-action {
    float: none;
    display: block;
    padding: 1em;
    height: auto;
    font-size: .5em;
    position: absolute;
    top: 1em;

    &.download {
        right: 15px;
    }

    &.logout {
        left: 15px;
    }
  }

  &.stop {
    border-bottom: 2px solid indianred;

    span {
      color: #ff7373;
    }
  }
  &-bench {
    float: right;
    margin-right: 10px;
    padding: 8px;
  }
}
.goalies:not(.no-select) {
    .btn {
        &:before {
            content:'';
            display: block;
            position: absolute;
            height: .2em;
            background:#333;
            left: .8em;
            right: .8em;
            top: .5em;
            border-radius: 50px;
            box-shadow: 0 1px 0 rgba(255,255,255,.3), 0 -1px 0 rgba(0,0,0,.3);

        }
        &.highlighted {
            position:relative;
            &:before {
                content:'';
                display: block;
                position: absolute;
                height: .2em;
                background:#fff;
                left: .8em;
                right: .8em;
                top: .5em;
                border-radius: 50px;
                box-shadow:
                    0 1px 0 rgba(255,255,255,.3),
                    0 -1px 0 rgba(0,0,0,.3),
                    0 0 1em rgba(0,255,0,1),
                    inset 0 0 .5rem rgba(0,255,0,.2),
                    inset 0 0 .1rem rgba(0,255,0,1),
                    inset 0 0 .1rem rgba(0,255,0,1),
                    inset 0 0 .15rem rgba(0,255,0,1),
                    inset 0 0 .15rem rgba(0,255,0,1);
            }
        }
    }
}
.btn-group {
  overflow: hidden;
  box-shadow: 0 .1rem .2rem rgba(0,0,0,.3);
  box-sizing: border-box;
  margin: .2707035rem;
  border-radius: .25em;
  display: flex;
  
  .btn {
    width: 33.33333333333333333334%;
    box-shadow: none;
    box-sizing: border-box;
    border-radius: 0;
    border-left: 1px solid #444;
    margin: 0;
    padding: 0;
    box-shadow:inset 0 .05rem 0 rgba(255,255,255,.3), inset 0 -.05rem 0 rgba(0,0,0,.3);
    //border-top: none;
    &:active, &.active {
      box-shadow: inset 0 .1rem .2rem rgba(0,0,0,.3);
    }
    &:first-child {
      border-left: none;
      border-radius: .25em 0 0 .25em;
      
    }
    &:last-child {
      border-radius: 0 .25em .25em 0;
      
      
    }
  }
}
.btn-row-1 {
  .btn {
    width: calc(100% - .2707035em * 2);
  }
}
.btn-row-2 {
  .btn {
    width: calc(100% / 2.001 - .2707035em * 2);
  }
}
.btn-row-3 {
  .btn {
    width: calc(100% / 3.001 - .2707035em * 2);
  }
}
.btn-row-4 {
  .btn {
    width: calc(100% / 4.001 - .2707035em * 2);
  }
}
.btn-row-5 {
  .btn {
    width: calc(100% / 5.001 - .2707035em * 2);
  }
}
.btn-row-6 {
  .btn {
    width: calc(100% / 6.001 - .2707035em * 2);
  }
}
.btn-row-7 {
  .btn {
    width: calc(100% / 7.001 - .2707035em * 2);
  }
}
.btn-row-8 {
  .btn {
    width: calc(100% / 8.001 - .2707035em * 2);
  }
}
.btn-row-9 {
  .btn {
    width: calc(100% / 9.001 - .2707035em * 2);
  }
}
.btn-row-10 {
  .btn {
    width: calc(100% / 10.001 - .2707035em * 2);
  }
}
.btn-row-11 {
  .btn {
    width: calc(100% / 11 - .2707035em * 2);
  }
}
.btn-row-12 {
  .btn {
    width: calc(100% / 12 - .2707035em * 2);
  }
}
.btn-row-13 {
  .btn {
    width: calc(100% / 13 - .2707035em * 2);
  }
}
.btn-row-15 {
  .btn {
    width: calc(100% / 15 - .2707035em * 2);
  }
}

.btn-row-6-stack {
    width: calc(100% / 3 * 2);
    margin: 0 auto;
    .btn {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.5em;
    }
}

.btn-row-fit {
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
display: -ms-flexbox;      /* TWEENER - IE 10 */
display: -webkit-flex;     /* NEW - Chrome */
display:flex;
  .btn {
    flex-grow: 1;
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display:flex;
    span {
      position:absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.upload-btn {
    background: rgba(0,0,0,.3);
    &.flashing {
        &:after {
            content: "";
            box-shadow: 0px 0px 15px 0px #4997c5, 0 0 7px white, 0px 0px 15px 0px #118cc8, 0 0 3px white, 0 0 2px white, inset 0px 0px 15px 0px #21b4ff;
            width: 12em;
            display: block;
            position: absolute;
            height: 6.5em;
            left: 0;
            top:0;
            border-radius: .25em;
            opacity: 0;
            animation: direction 1s infinite;
        }
    }
}

.sync-games {
  &.flashing {
    &:after {
      content: "";
      box-shadow: 0px 0px 15px 0px #4997c5, 0 0 7px white, 0px 0px 15px 0px #118cc8, 0 0 3px white, 0 0 2px white, inset 0px 0px 15px 0px #21b4ff;
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: .25em;
      opacity: 0;
      animation: direction 1s infinite;
    }
  }
}

.top-row {
  .btn {
    span {
      font-size: 1.4em;
      line-height: 1.1em;
    }
  }
}

.faceoff-highlight {
  color: gold;
}

.faceoff-border {
    border: 3px solid gold;
}
