.select-game {
    h1 {
        font-size: 2em;
        text-align:center;
        margin-bottom: .5em;
        background: #000;
        border-bottom: 1px solid #555;
        padding: 1em 0;
    }
    .card {
        margin-top: .35em;
        h3 {
            font-size: 1.3em;
            line-height: normal;
        }
        .vs {
            opacity: .3;
        }
        .teams {
            width: 66%;
            float:left;
        }
    }
    h2 {
        font-size: 1.5em;
        opacity: .3;
        margin-bottom: .25em;
    }
    .next-up {
        border-top: none!important;
        box-shadow: none!important;
        .card {
            width: calc(100% - 10px);
            margin: .35em 0 0 0 ;
            width: 100%;
            h3 {
                font-size:1.6em;
            }
            .time-loc {
                font-size: 1.3em;
            }
        }
    }
    .time-loc {
        text-align: right;
        line-height: normal;
        hyphens: none;
    }
    .modal-content {
        h1 {
            background: none;
            border: none;
            padding: 0;
            font-size: 1.5em;
            margin-bottom: 1em;
        }
    }
    .section {
        margin: 1em;
    }
    .completed-games {
        .card {
            width: calc(100% - 10px);
            margin: .35em 0 0 0 ;
            width: 100%;
            margin-bottom: 1em;
            .teams {
                width: 50%;
            }
            .time-loc {
                float:left;
                padding-right: 1.5em;
                width:25%;
            }
            .view-stats {
                .btn {
                    padding: 5px;
                    background-color: green;
                }
            }
            .upload-btn {
                width: 15%;
                position:absolute;
                right: 5px;
                top: 4px;
                bottom: 5px;
                text-align: center;
                height: auto;
                display: block;
                @include gradient(#2993C3,#226D9B);
                span {
                    display: block;
                    width: 100%;
                    text-align:center;
                    position: absolute;
                    transform: translate(-50%,-50%);
                    top: 49%;
                    left: 50%;
                    font-size: 1.3em;
                }
            }
        }
    }
    .upcoming {
        .time-loc {
width: 33.333333%;
        }
    }
}



.card-container {
    display: flex;
    flex-wrap: wrap;
    .card {
        display: flex;
    }
}
