.login {
    width: 50%;
    transform: translate(-50%);
    margin-left: 50%;
    margin-top:32%;
    h1 {
        text-align: center;
        font-size: 2em;
    }
    .login-form {
        margin: 2em 0;

        border-top: 1px solid #191919;
        border-bottom: 1px solid #555;

        .btn.save {
            line-height: 2.2em;
            @media screen and (max-width: 767px) {
                font-size: 1.8em;
                width: 100%;
                margin: 0;
            }
        }
        .inner {
            border-top: 1px solid #555;
            border-bottom: 1px solid #191919;
            overflow: hidden;
            padding: 2em 0 1.25em 0;
        }
    }
    .logo {
        width: 45%;
        background: #fff;
        padding: 10px 13px 7px 13px;
        border-radius: 5px;
        margin: 0 auto;
        box-shadow: 0 5px 10px rgba(0,0,0,.5);
    }
    @media screen and (max-width:767px) {
        width: 90%;
        font-size: 1.5em;
    }
}
